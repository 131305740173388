const messages = {
  es: {
    translations: {
      signup: {
        title: "Registro",
        toasts: {
          success:
            "¡El usuario ha sido creado satisfactoriamente! ¡Ahora inicia sesión!",
          fail: "Error creando el usuario. Verifica la data reportada.",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Regístrate",
          login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
        },
      },
      login: {
        title: "¡Sea bienvenido!",
        form: {
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Ingresa",
          register: "¿No tienes cuenta? ¡Regístrate!",
          recover: "Olvidé mi contraseña",
        },
      },
      recoverPassword: {
        title: "Olvidaste tu contraseña?",
        subTitle:
          "¡Sin problemas! Simplemente ingrese su correo electrónico y le enviaremos instrucciones de inicio de sesión.",
        form: {
          email: "Ingrese correo electrónico",
        },
        buttons: {
          submit: "Recuperar contraseña",
          return: "Regresar",
        },
      },
      auth: {
        toasts: {
          success: "¡Inicio de sesión exitoso!",
        },
      },
      dashboard: {
        epoch: {
          buttons: {
            today: "Hoy",
            week: "Semana",
            month: "Mes",
            year: "Año",
          },
        },
        charts: {
          perDay: {
            title: "Tickets hoy: ",
          },
          attendantsChart: {
            title: "Llamadas activas de asistentes",
            sideTitle: "Llamadas",
          },
          progressBar: {
            callsByDepartment: "Llamadas por departamento",
            callsByAttendant: "Llamadas del asistente",
            ratingsByAttendant: "Calificaciones del asistente",
          },
        },
        departments: {
          title: "Departamentos",
        },
        messages: {
          inAttendance: {
            title: "En servicio",
          },
          waiting: {
            title: "Esperando",
          },
          closed: {
            title: "Finalizado",
          },
          connection: {
            title: "Conexiones",
          },
          waitingTime: {
            title: "Tiempo medio de espera",
          },
          serviceTime: {
            title: "Tiempo promedio de servicio",
          },
          averageRating: {
            title: "Puntuación media",
          },
          contacts: {
            title: "Contactos",
          },
          messages: {
            title: "Mensajes",
          },
          reviews: {
            title: "Evaluaciones",
          },
        },
        infoCard: {
          ticketsToday: "Número de Tickets que se manejan.",
          awaitingService: "Número de Tickets en espera de servicio.",
          ticketsSolved: "Número de Tickets resueltos.",
          connections: "Número de canales conectados.",
          waitingTime:
            "Tiempo medio de espera de los tickets para ser atendidos.",
          serviceTime:
            "Tiempo medio en que los operadores realizan el servicio.",
          averageRating: "Valoración media de la valoración del servicio.",
          contactNumber: "Número total de contactos.",
          messagesNumber: "Número total de mensajes intercambiados.",
          reviews: "Número total de opiniones recibidas.",
        },
      },
      connections: {
        title: "Conexiones",
        toasts: {
          deleted:
            "¡La conexión de WhatsApp ha sido borrada satisfactoriamente!",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage: "¿Estás seguro? Este proceso no puede ser revertido.",
          disconnectTitle: "Desconectar",
          disconnectMessage: "Estás seguro? Deberá volver a leer el código QR",
        },
        buttons: {
          add: "Agrega conexión",
          disconnect: "Desconectar",
          tryAgain: "Inténtalo de nuevo",
          qrcode: "QR CODE",
          newQr: "Nuevo QR CODE",
          connecting: "Conectando",
          restart: "Reinicia Backend",
        },
        toolTips: {
          disconnected: {
            title: "No se pudo iniciar la sesión de WhatsApp",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y vuelva a intentarlo o solicite un nuevo código QR",
          },
          qrcode: {
            title: "Esperando la lectura del código QR",
            content:
              "Haga clic en el botón 'CÓDIGO QR' y lea el Código QR con su teléfono celular para iniciar la sesión",
          },
          connected: {
            title: "Conexión establecida",
          },
          timeout: {
            title: "Se perdió la conexión con el teléfono celular",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y que WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
          },
        },
        table: {
          name: "Nombre",
          status: "Estado",
          lastUpdate: "Última Actualización",
          default: "Por Defecto",
          actions: "Acciones",
          session: "Sesión",
          channel: "Canal",
        },
      },
      permissionModal: {
        title: "Acción no permitida",
        content: "No tienes permiso para realizar la acción.",
      },
      whatsappModal: {
        title: {
          add: "Agrega Conexión",
          edit: "Edita Conexión",
        },
        form: {
          name: "Nombre de la conexión",
          default: "Por Defecto",
          channel: "Canal",
          farewellMessage: "Mensaje de despedida",
          whatsapp: "WhatsApp",
          facebook: "Facebook",
          telegram: "Telegram",
          instagram: "Instagram",
          whatsappcloud: "WhatsApp API",
          connectionInfo: "Información de conexión",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "WhatsApp guardado satisfactoriamente.",
      },
      qrCode: {
        title: "Para conectarse a Notificarme:",
        firstPoint: "Abre WhatsApp en tu móvil.",
        secondPoint: {
          partOne: "Toque en ",
          moreOptions: "Mas opciones ",
          partTwo: " o ",
          configurations: "Ajustes ",
          partThree: "y seleccione Dispositivos conectados.",
        },
        thirdPoint: "Presiona Conectar un dispositivo.",
        fourthPoint:
          "Apunta tu celular a esta pantalla para capturar el código.",
        message: "Lea el QRCode para iniciar sesión",
      },
      contacts: {
        title: "Contactos",
        toasts: {
          deleted: "¡Contacto borrado satisfactoriamente!",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Borrar",
          importTitlte: "Importar contactos",
          deleteMessage:
            "¿Estás seguro que deseas borrar este contacto? Todos los tickets relacionados se perderán.",
          importMessage:
            "¿Quieres importar todos los contactos desde tu teléfono?",
        },
        buttons: {
          import: "Importar Contactos",
          export: "Exportar Contactos",
          add: "Agregar Contacto",
        },
        table: {
          name: "Nombre",
          whatsapp: "WhatsApp",
          email: "Correo Electrónico",
          company: "Compañía",
          actions: "Acciones",
        },
      },
      contactModal: {
        title: {
          add: "Agregar contacto",
          edit: "Editar contacto",
        },
        form: {
          mainInfo: "Detalles del contacto",
          extraInfo: "Información adicional",
          name: "Nombre",
          number: "Número de Whatsapp",
          email: "Correo Electrónico",
          company: "Compañía",
          extraName: "Nombre del Campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Agregar información",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Contacto guardado satisfactoriamente.",
      },
      quickAnswersModal: {
        title: {
          add: "Agregar respuesta rápida",
          edit: "Editar respuesta rápida",
        },
        form: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Respuesta rápida guardada correctamente.",
      },
      queueModal: {
        title: {
          add: "Agregar cola",
          edit: "Editar cola",
        },
        notification: {
          title: "¡Sector guardado con éxito!",
        },
        form: {
          name: "Nombre",
          color: "Color",
          greetingMessage: "Mensaje de saludo",
          startWork: "Apertura",
          endWork: "Cierre",
          absenceMessage: "Mensaje de ausencia",
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Ahorrar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Agregar usuario",
          edit: "Editar usuario",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
          whatsapp: "Conexión estándar",
          profile: "Perfil",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Usuario guardado satisfactoriamente.",
      },
      chat: {
        noTicketMessage: "Selecciona un ticket para empezar a chatear.",
        noChatDescription:
          "Envía y recibe mensajes sin tener que mantener tu teléfono conectado a Internet",
        noChatDescriptionText:
          "Usa NotificaMe en tantas computadoras como quieras y en un celular al mismo tiempo.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Nuevo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Linhas",
      },
      tickets: {
        toasts: {
          deleted: "El ticket en el que estabas ha sido borrado.",
        },
        notification: {
          message: "Mensaje de",
        },
        tabs: {
          open: { title: "Bandeja" },
          closed: { title: "Resueltos" },
          search: { title: "Buscar" },
        },
        search: {
          placeholder: "Buscar tickets y mensajes.",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      templateOnlyBar: {
        warningBefore:
          "Solo puede responder a esta conversación utilizando una plantilla de mensaje debido a ",
        warningAfter: "restricciones de ventana de mensaje de 24 horas",
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Escriba para buscar usuarios",
        fieldQueueLabel: "Transferir a la cola",
        fieldConnectionLabel: "Transferir to conexión",
        fieldQueuePlaceholder: "Seleccione una cola",
        fieldConnectionPlaceholder: "Seleccione una conexión",
        noOptions: "No se encontraron usuarios con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Cola",
        assignedHeader: "Trabajando en",
        noTicketsTitle: "¡Nada acá!",
        connectionTitle: "Conexión que se está utilizando actualmente.",
        noTicketsMessage:
          "No se encontraron tickets con este estado o término de búsqueda",
        items: {
          queueless: "Sin sector",
          accept: "Aceptar",
          spy: "Espía",
          close: "cerca",
          reopen: "Reabrir",
        },
        buttons: {
          accept: "Responder",
          acceptBeforeBot: "Aceptar",
          start: "empezar",
          cancel: "Cancelar",
        },
        acceptModal: {
          title: "Aceptar chat",
          queue: "Seleccionar sector",
        },
      },
      newTicketModal: {
        title: "Crear Ticket",
        fieldLabel: "Escribe para buscar un contacto",
        add: "Añadir",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexiones",
          tickets: "Conversaciones",
          contacts: "Contactos",
          quickAnswers: "Respuestas rápidas",
          queues: "Linhas",
          tags: "Tags",
          schedules: "Horarios",
          administration: "Administración",
          users: "Usuarios",
          settings: "Configuración",
          api: "uso de la API",
          apidocs: "Documentación",
          apititle: "API",
          apikey: "API Key",
          token: "Token",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Cerrar Sesión",
          },
        },
      },
      notifications: {
        noTickets: "Sin notificaciones.",
      },
      queues: {
        title: "Linhas",
        notifications: {
          queueDeleted: "El sector ha sido eliminado.",
        },
        table: {
          name: "Nombre",
          color: "Color",
          greeting: "Mensaje de saludo",
          actions: "Comportamiento",
          startWork: "Apertura",
          endWork: "Cierre",
        },
        buttons: {
          add: "Agregar cola",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "¿Estás seguro? ¡Esta acción no se puede revertir! Los tickets en esa cola seguirán existiendo, pero ya no tendrán ninguna cola asignada.",
        },
      },
      queueSelect: {
        inputLabel: "Linhas",
      },
      quickAnswers: {
        title: "Respuestas rápidas",
        table: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar respuesta rápida",
        },
        toasts: {
          deleted: "Respuesta rápida eliminada correctamente",
        },
        searchPlaceholder: "Buscar ...",
        confirmationModal: {
          deleteTitle:
            "¿Está seguro de que desea eliminar esta respuesta rápida?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
      },
      users: {
        title: "Usuarios",
        table: {
          name: "Nombre",
          email: "Correo Electrónico",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar usuario",
        },
        toasts: {
          deleted: "Usuario borrado satisfactoriamente.",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage:
            "Toda la información del usuario se perderá. Los tickets abiertos de los usuarios se moverán a la cola.",
        },
      },
      templates: {
        title: "Plantillas",
        table: {
          type: "Tipo",
          status: "Estado",
          name: "Nombre",
          email: "Correo Electrónico",
          category: "Categoria",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
          actions: "Acciones",
          header: "Header",
          body: "Body",
          footer: "Footer",
          statuses: {
            APPROVED: "Aprobado",
            PENDING: "Pendiente",
            REJECTED: "Rechazado",
          },
          types: {
            UTILITY: "Utilidad",
            MARKETING: "Marketing",
            AUTHENTICATION: "Autenticación",
          },
        },
        buttons: {
          add: "Agregar plantilla",
        },
        toasts: {
          deleted: "Plantilla eliminada con éxito.",
        },
        templateModal: {
          title: {
            create: "Agregar plantilla",
            update: "Actualizar plantilla",
          },
          connection: "Conexión",
          name: "Nombre",
          header: "Encabezado",
          body: "Cuerpo",
          footer: "Pie de página",
          buttons: {
            create: "Crear",
            update: "Actualizar",
            cancel: "Cancelar",
          },
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "Todos los datos de la plantilla se perderán.",
        },
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        },
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "¿Está seguro de que desea eliminar esta etiqueta?",
          deleteMessage: "Esta acción no se puede revertir.",
        },
        table: {
          name: "Nombre",
          color: "Color",
          tickets: "Sellos discográficos",
          actions: "Acciones",
          contacts: "Contactos",
        },
        buttons: {
          add: "Agregar etiqueta",
        },
        toasts: {
          deleted: "Etiqueta eliminada correctamente.",
        },
      },
      settings: {
        success: "Configuración guardada satisfactoriamente.",
        title: "Configuración",
        settings: {
          userCreation: {
            name: "Creación de usuarios",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
        },
      },
      CheckMsgIsGroup: {
        name: "Ignorar Mensajes Grupales",
        options: {
          enabled: "Habilitado",
          disabled: "Deshabilitado",
        },
      },
      sideMenu: {
        name: "Menú lateral de inicio",
        note: "Si está habilitado, el menú lateral comenzará cerrado",
        options: {
          enabled: "Abrir",
          disabled: "Cerrado",
        },
      },
      closeTicketApi: {
        name: "Cerrar ticket enviado API",
        note: "Cierra automáticamente el ticket cuando se envía a través de API",
        options: {
          enabled: "Habilitado",
          disabled: "Deshabilitado",
        },
      },
      darkMode: {
        name: "Habilitar modo oscuro",
        note: "Cambiar entre el modo claro y el modo oscuro",
        options: {
          enabled: "Habilitado",
          disabled: "Deshabilitado",
        },
      },
      timeCreateNewTicket: {
        name: "Crear nuevo ticket después",
        note: "Selecciona el tiempo que tardará en abrir un nuevo ticket si el cliente te vuelve a contactar",
        options: {
          10: "10 Segundos",
          30: "30 Segundos",
          60: "1 minuto",
          300: "5 minutos",
          1800: "30 minutos",
          3600: "1 hora",
          7200: "2 horas",
          21600: "6 horas",
          43200: "12 horas",
          86400: "24 horas",
          604800: "7 días",
          1296000: "15 días",
          2592000: "30 días",
        },
      },
      sortBy: {
        nameOpen: "Ordenar tickets abiertos por",
        namePending: "Ordenar tickets pendientes por",
        nameClosed: "Ordenar tickets cerrados por",
        note: "Seleccione el orden en el que desea ver las entradas",
        options: {
          asc: "mayor",
          desc: "Más reciente"
        }
      },
      messagesList: {
        header: {
          assignedTo: "Asignado a:",
          buttons: {
            return: "Devolver",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceptar",
            solverWithoutGoodbye: "Solucionador sin adiós",
          },
        },
      },
      messagesInput: {
        placeholderOpen:
          "Escriba un mensaje o presione '' / '' para usar las respuestas rápidas registradas",
        placeholderClosed:
          "Vuelva a abrir o acepte este ticket para enviar un mensaje.",
        signMessage: "Firmar",
      },
      contactDrawer: {
        header: "Detalles del contacto",
        buttons: {
          edit: "Editar contacto",
        },
        extraInfo: "Otra información",
      },
      searchDrawer: {
        header: "Buscar mensaje",
        searchLabel: "Buscar",
        yesterday: "ayer",
        locale: "es",
      },
      ticketOptionsMenu: {
        delete: "Borrar",
        transfer: "Transferir",
        confirmationModal: {
          title: "¿Borrar ticket #",
          titleFrom: "del contacto ",
          message:
            "¡Atención! Todos los mensajes Todos los mensajes relacionados con el ticket se perderán.",
        },
        buttons: {
          delete: "Borrar",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Borrar",
        reply: "Responder",
        confirmationModal: {
          title: "¿Borrar mensaje?",
          message: "Esta acción no puede ser revertida.",
        },
      },
      languages: {
        undefined: "Idioma",
        "pt-BR": "Portugués",
        es: "Español",
        en: "Inglés",
      },
      tagModal: {
        title: {
          add: "Crear etiqueta",
          edit: "Editar etiqueta",
        },
        form: {
          name: "Nombre",
          color: "Color",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Etiqueta guardada con éxito.",
      },
      backendErrors: {
        TICKET_NOT_FOUND: "Ticket no encontrado.",
        ERR_TAG_NAME_ALREADY_EXISTS: "Ya existe una etiqueta con ese nombre.",
        ERR_NO_OTHER_WHATSAPP:
          "Debe haber al menos una conexión de WhatsApp predeterminada.",
        ERR_NO_DEF_WAPP_FOUND:
          "No se encontró WhatsApp predeterminado. Verifique la página de conexiones.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sesión de WhatsApp no ​​está inicializada. Verifique la página de conexiones.",
        ERR_WAPP_CHECK_CONTACT:
          "No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones.",
        ERR_WAPP_INVALID_CONTACT: "Este no es un número de whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "No se pudieron descargar los medios de WhatsApp. Verifique la página de conexiones.",
        ERR_INVALID_CREDENTIALS: "Error de autenticación. Vuelva a intentarlo.",
        ERR_SENDING_WAPP_MSG:
          "Error al enviar el mensaje de WhatsApp. Verifique la página de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se pudo borrar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
        ERR_SESSION_EXPIRED: "Sesión caducada. Inicie sesión.",
        ERR_USER_CREATION_DISABLED:
          "La creación de usuarios fue deshabilitada por el administrador.",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
        ERR_NO_SETTING_FOUND:
          "No se encontró ninguna configuración con este ID.",
        ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
        ERR_NO_TICKET_FOUND: "No se encontró ningún ticket con este ID.",
        ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
        ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
        ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",
        ERR_CREATING_TICKET: "Error al crear el ticket en la base de datos.",
        ERR_FETCH_WAPP_MSG:
          "Error al obtener el mensaje en WhtasApp, tal vez sea demasiado antiguo.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Este color ya está en uso, elija otro.",
        ERR_WAPP_GREETING_REQUIRED:
          "El mensaje de saludo es obligatorio cuando hay más de una cola.",
        ERR_USER_CREATION_COUNT:
          "Se alcanzó el límite de usuarios; para cambiar, comuníquese con el soporte.",
        ERR_CONNECTION_CREATION_COUNT:
          "Se alcanzó el límite de conexión, para cambiar, comuníquese con el soporte.",
      },
    },
  },
};

export { messages };
