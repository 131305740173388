import React, { useContext, useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import sortReversedIcon from "../../assets/sortReversedIcon.png";
import sortIcon from "../../assets/sortIcon.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import SearchedTicketsList from "../SearchedTicketsList";
import { ThemeContext } from "../../context/Theme/ThemeContext";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  managerTopBar: {
    padding: "0 20px 0 10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // background: `${!darkMode ? "blue" : "red"}`,
  },

  tabsHeader: {
    // width: "100%",
    display: "flex",
    // flex: "none",
    // backgroundColor: `${!darkMode ? "" : "#f0f2f5"}`,
    height: 59,
    padding: "16 0px",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    padding: "0 20px",
    marginLeft: 10,
    marginTop: "-5px",
    top: 0,
    minWidth: 20,
    width: 20,
    height: 20,
  },

  closedTab: {
    padding: "0 20px",
    marginLeft: 10,
    marginTop: "-7px",
    top: 0,
    minWidth: 20,
    width: 20,
    height: 20,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // background: `${!darkMode ? "" : "#f0f2f5"}`,
    padding: theme.spacing(1),
  },

  searchContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 10,
    gap: 8,
  },

  searchInputWrapper: {
    flex: 1,
    // background: `${!darkMode ? "" : "#f0f2f5"}`,
    display: "flex",
    borderRadius: 8,
    padding: 4,
    margin: 7,
  },

  searchIcon: {
    fontSize: "1.4em",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
    marginRight: 30,
  },

  filterIconFalse: {
    cursor: "pointer",
    borderRadius: "50%",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.5,
    marginRight: 5,

    // svg font size
    "& > svg": {
      fontSize: "1.4em",
    },
  },

  filterIconTrue: {
    backgroundColor: "#00a884",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "50%",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.5,
    marginRight: 5,

    "& > svg": {
      fontSize: "1.4em",
    },
  },

  searchInput: {
    fontSize: "0.9em",
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  selectedTab: {
    color: "#009688",
  },

  newTicketBtn: {
    // color: `${!darkMode ? "#fbfbfc" : "#757575"}`,
    margin: "14px 5px 0 16px",
    cursor: "pointer",
  },

  badge: {
    margin: 0,
    top: "-10px",
    right: "-10px",
    padding: 0,
    // position: "absolute",

    "& > span": {
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: "#ff0063",
      color: "#fff",
      fontSize: "0.9em",
      fontWeight: 600,
    },
  },

  show: {
    display: "block",
  },

  hide: {
    display: "none !important",
  },
}));

function TicketsManager() {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [searched, setSearched] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(true);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showUnread, setShowUnread] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    sortByOpen: '',
    sortByPending: '',
    sortByClosed: ''
  });

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/settings");

        const getSetting = (key) => data.find((s) => s.key === key)?.value || '';

        setSortOrder({
          sortByOpen: getSetting("sortByOpen"),
          sortByPending: getSetting("sortByPending"),
          sortByClosed: getSetting("sortByClosed")
        });
      } catch (err) {
        setSortOrder({
          sortByOpen: 'desc',
          sortByPending: 'desc',
          sortByClosed: 'desc'
        });
      }
    };
    fetchSession();
  }, []);

  const userQueueIds = user.queues?.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  const darkMode = localStorage.getItem("darkMode") === "true" ? true : false;

  useEffect(() => {
    if (user.profile?.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchParam("");
  }, [tabOpen, tab]);

  useEffect(() => {
    if (searchParam == "") {
      setSearched(0);
      setSearchLoading(false);
    }
  }, [searchParam]);

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleSortChange = (tab) => {
    const sortField =
      tab === "open" ? sortOrder.sortByOpen :
        tab === "pending" ? sortOrder.sortByPending :
          tab === "closed" ? sortOrder.sortByClosed : null;

    if (tab == "open") {
      setSortOrder({
        sortByOpen: sortField === 'asc' ? 'desc' : 'asc',
        sortByPending: sortOrder.sortByPending,
        sortByClosed: sortOrder.sortByClosed
      });
    } else if (tab == "pending") {
      setSortOrder({
        sortByOpen: sortOrder.sortByOpen,
        sortByPending: sortField === 'asc' ? 'desc' : 'asc',
        sortByClosed: sortOrder.sortByClosed
      });
    } else if (tab == "closed") {
      setSortOrder({
        sortByOpen: sortOrder.sortByOpen,
        sortByPending: sortOrder.sortByPending,
        sortByClosed: sortField === 'asc' ? 'desc' : 'asc'
      });
    }
  };

  const getSortTooltip = (tab, sortOrder) => {
    const sortField =
      tab === "open" ? sortOrder.sortByOpen :
        tab === "pending" ? sortOrder.sortByPending :
          tab === "closed" ? sortOrder.sortByClosed : null;

    return sortField === 'asc' ? "Ordenar tickets (mais antigos)" : "Ordenar tickets (mais recentes)";
  };

  const getSortIcon = (tab, sortOrder, darkMode) => {
    const sortField =
      tab === "open" ? sortOrder.sortByOpen :
        tab === "pending" ? sortOrder.sortByPending :
          tab === "closed" ? sortOrder.sortByClosed : null;

    const icon = sortField === 'asc' ? sortIcon : sortReversedIcon;
    const color = darkMode ? "#c7c7c7" : "#6e6f70";

    return <img src={icon} alt="Sort" width={24} height={24} color={color} />;
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <Box
        className={classes.managerTopBar}
        style={{
          backgroundColor: `${darkMode ? "#202c33" : "#f0f2f5"}`,
        }}
      >
        <Avatar
          alt={"logo"}
          src={"/logo.png"}
          variant="square"
          style={{ marginLeft: 10, marginRight: 10 }}
        />

        <Paper
          elevation={0}
          square
          className={classes.tabsHeader}
          style={{
            backgroundColor: `${darkMode ? "#202c33" : "#f0f2f5"}`,
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            variant="fullWidth"
            indicatorColor="transparent"
            textColor="primary"
            aria-label="icon label tabs example"
          >
            <Tab
              classes={{ root: classes.tab }}
              icon={
                <Tooltip title="Tickets abertos">
                  <ChatBubbleOutlineIcon />
                </Tooltip>
              }
              label={
                <Badge
                  overlap="rectangular"
                  className={classes.badge}
                  badgeContent={openCount}
                ></Badge>
              }
              value="open"
              onClick={() => setTabOpen("open")}
            />

            <Tab
              classes={{ root: classes.tab }}
              icon={
                <Tooltip title="Tickets pendentes">
                  <AnnouncementIcon />
                </Tooltip>
              }
              label={
                <Badge
                  overlap="rectangular"
                  className={classes.badge}
                  badgeContent={pendingCount}
                  color="primary"
                ></Badge>
              }
              value="pending"
              onClick={() => setTabOpen("pending")}
            />

            <Tab
              value="closed"
              icon={
                <Tooltip title="Tickets fechados">
                  <CheckBoxIcon />
                </Tooltip>
              }
              label={
                <Badge
                  overlap="rectangular"
                  className={classes.badge}
                  badgeContent={0}
                  color="primary"
                ></Badge>
              }
              classes={{ root: classes.closedTab }}
            />

            <Box
              className={classes.newTicketBtn}
              style={{
                color: `${darkMode ? "#c7c7c7" : "#6e6f70"}`,
              }}
              onClick={() => setNewTicketModalOpen(true)}
            >
              <Tooltip title="Nova Conversa">
                <AddBoxIcon />
              </Tooltip>
            </Box>

            <Box
              className={classes.newTicketBtn}
              style={{
                color: `${darkMode ? "#c7c7c7" : "#6e6f70"}`,
              }}
              onClick={() => handleSortChange(tab)}
            >
              <Tooltip title={getSortTooltip(tab, sortOrder)}>
                {tab === "open" || tab === "pending" || tab === "closed" ? getSortIcon(tab, sortOrder, darkMode) : null}
              </Tooltip>
            </Box>
          </Tabs>
          <Box className={classes.newTicketBtn}>
            <Tooltip title="Filtro de setores">
              <TicketsQueueSelect
                style={{
                  marginLeft: 6,
                }}
                selectedQueueIds={selectedQueueIds}
                userQueues={user?.queues}
                /* onChange={(values) => setSelectedQueueIds(values)} */
                setSelectedQueueIds={setSelectedQueueIds}
              />
            </Tooltip>
          </Box>
        </Paper>
      </Box>

      <TabPanel className={classes.ticketsWrapper}>
        <Box className={classes.searchContainer}>
          <div
            className={classes.searchInputWrapper}
            style={{
              backgroundColor: `${darkMode ? "#202c33" : "#f0f2f5"}`,
            }}
          >
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={"Pesquisar ou começar uma conversa"}
              type="search"
              onChange={(e) => setSearchParam(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearched(searched + 1);
                  setSearchLoading(true);
                }
              }}
              // loading on end of input
              endAdornment={
                searchLoading &&
                searched > 0 && (
                  <CircularProgress style={{ color: "#009688" }} size={20} />
                )
              }
            />
          </div>
          <Box
            className={
              showUnread ? classes.filterIconTrue : classes.filterIconFalse
            }
            onClick={() => {
              setShowUnread(!showUnread);
            }}
          >
            <FilterListIcon />
          </Box>
        </Box>

        <Box display={tab === "open" ? "" : "none"} height={"100%"}>
          {searched === 0 ? (
            <TicketsList
              status="open"
              showAll={showAllTickets}
              showUnread={showUnread}
              selectedQueueIds={selectedQueueIds}
              sortBy={sortOrder.sortByOpen}
              updateCount={(val) => setOpenCount(val)}
              style={applyPanelStyle("open")}
            />
          ) : (
            <SearchedTicketsList
              searchParam={searchParam}
              tags={selectedTags}
              showAll={showAllTickets}
              showUnread={showUnread}
              selectedQueueIds={selectedQueueIds}
              style={applyPanelStyle("open")}
              searched={searched}
              setSearchLoading={setSearchLoading}
            />
          )}
        </Box>

        <Box display={tab === "pending" ? "" : "none"} height={"100%"}>
          <TicketsList
            status="pending"
            showAll
            sortBy={sortOrder.sortByPending}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
          />
        </Box>

        <Box display={tab === "closed" ? "" : "none"} height={"100%"}>
          <TicketsList
            status="closed"
            showAll
            sortBy={sortOrder.sortByClosed}
            selectedQueueIds={selectedQueueIds}
          />
        </Box>

      </TabPanel>
    </Paper>
  );
}

export default TicketsManager;
