import React, { useContext, useEffect, useRef, useState } from "react";

import { Box, Divider, Tooltip, withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { AlternateEmail, LocalSee } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneIcon from "@material-ui/icons/Done";
import GroupIcon from "@material-ui/icons/Group";
import MicIcon from "@material-ui/icons/Mic";
import NoteIcon from "@material-ui/icons/Note";
import PersonIcon from "@material-ui/icons/Person";
import ReplayIcon from "@material-ui/icons/Replay";
import VisibilityIcon from "@material-ui/icons/Visibility";
import clsx from "clsx";
import { format, isSameDay, isYesterday, parseISO } from "date-fns";
import { useHistory, useParams } from "react-router-dom";

import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import MarkdownWrapper from "../MarkdownWrapper";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  lastMessageSpan: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    marginBottom: 4,
  },

  lastMessageIcon: {
    fontSize: "1.1em",
  },

  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  lastMessageTime: {
    fontSize: "0.8em",
    justifySelf: "flex-end",
  },

  tagsBox: {
    width: "100%",
    overflow: "hidden",
    position: "absolute",
    top: 53,
    left: 72,
    display: "flex",
    gap: 4,
    fontWeight: 500,
    width: "100%",
    alignItems: "left",
  },

  tag: {
    color: "#fff",
    fontSize: "0.7em",
    padding: "0 5px",
    borderRadius: "20px",
    textShadow: "0 0 3px #000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  lastUnreadMessageTime: {
    fontSize: "0.8em",
    color: "#3d8c40",
    justifySelf: "flex-end",
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: 20,
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },

  bottomButton: {
    top: "12px",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    left: "50%",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  userTag: {
    position: "absolute",
    marginRight: 5,
    right: 20,
    top: 12,
    // bottom: 30,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
  },

  contactAvatar: {
    width: 40,
    height: 40,
    backgroundColor: "#bdbdbd",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      color: "#fff",
      fontSize: "2em",
    },
  },
}));

function TicketListItem({ ticket }) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const [
    acceptTicketWithouSelectQueueOpen,
    setAcceptTicketWithouSelectQueueOpen,
  ] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [audioDuration, setAudioDuration] = useState("0:00");
  const userTagRef = useRef(null);
  const [userTagWidth, setUserTagWidth] = useState(0);

  const lastUpdate = ticket.lastMessageObj?.createdAt || ticket.updatedAt;

  const getAudioDuration = (media) => {
    const audio = new Audio(media);
    audio.onloadedmetadata = (e) => {
      if (audio.readyState > 0) {
        const minutes = Math.floor(audio.duration / 60);
        const seconds = Math.floor(audio.duration - minutes * 60);
        const secondsWithZero = seconds < 10 ? `0${seconds}` : seconds;
        setAudioDuration(`${minutes}:${secondsWithZero}`);
      }
      return "0:00";
    };
  };

  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        const response = await fetch(ticket?.contact?.profilePicUrl);
        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setProfilePicUrl(url);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (ticket?.contact?.profilePicUrl) {
      fetchProfilePic();
    }
  }, [ticket?.contact?.profilePicUrl]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    const handleResize = () => {
      if (userTagRef.current && userTagRef.current.offsetWidth > 0) {
        if (ticket.status == "pending") {
          setUserTagWidth(userTagRef.current.offsetWidth - 48);
        } else if (ticket.status == "closed") {
          setUserTagWidth(userTagRef.current.offsetWidth - 24);
        } else {
          setUserTagWidth(userTagRef.current.offsetWidth);
        }
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (userTagRef.current) {
      observer.observe(userTagRef.current);
    }

    return () => {
      if (userTagRef.current) {
        observer.unobserve(userTagRef.current);
      }
    };
  }, []);

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${id}`);
  };

  const queueName = (selectedTicket) => {
    let name = null;
    let color = null;
    user.queues &&
      user.queues.forEach((userQueue) => {
        if (userQueue.id === selectedTicket.queueId) {
          name = userQueue.name;
          color = userQueue.color;
        }
      });
    return {
      name,
      color,
    };
  };

  const handleOpenAcceptTicketWithouSelectQueue = () => {
    setAcceptTicketWithouSelectQueueOpen(true);
  };

  const handleReopenTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${id}`);
  };

  const handleViewTicket = async (id) => {
    history.push(`/tickets/${id}`);
  };

  const handleReturnTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "pending",
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${id}`);
  };

  const handleClosedTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${id}`);
  };

  const handleSelectTicket = (id) => {
    history.push(`/tickets/${id}`);
  };

  const generateLastMessage = (lastMessage, isGroup) => {
    let message = "";

    if (!lastMessage?.fromMe && isGroup) {
      message += `${lastMessage?.contact?.name}: `;
    } else if (lastMessage?.fromMe && isGroup) {
      message += "Você: ";
    }

    // types: ogg, jpeg, png, webp, pdf

    if (lastMessage.mediaType === "audio") {
      return (
        <span className={classes.lastMessageSpan}>
          {message}
          <MicIcon className={classes.lastMessageIcon} />
          {getAudioDuration(lastMessage.mediaUrl)}

          {audioDuration}
        </span>
      );
    }
    if (
      lastMessage.body.endsWith(".jpeg" || ".png") ||
      lastMessage.mediaType === "image"
    ) {
      return (
        <span className={classes.lastMessageSpan}>
          {message}
          <LocalSee className={classes.lastMessageIcon} />
          Foto
        </span>
      );
    }
    if (lastMessage.body.endsWith(".pdf")) {
      return (
        <span className={classes.lastMessageSpan}>
          {message}
          <DescriptionIcon className={classes.lastMessageIcon} />
          {lastMessage.body}
        </span>
      );
    }
    if (lastMessage.body.endsWith(".webp")) {
      return (
        <span className={classes.lastMessageSpan}>
          {message}
          <NoteIcon className={classes.lastMessageIcon} />
          Figurinha
        </span>
      );
    }
    if (lastMessage.mediaType === "vcard") {
      const array = lastMessage.body.split("\n");
      const obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        const values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }

      return (
        <span className={classes.lastMessageSpan}>
          <PersonIcon className={classes.lastMessageIcon} />
          {contact}
        </span>
      );
    }
    if (lastMessage.mediaType === "multi_vcard") {
      const array = lastMessage.body
        .replace("[{", "")
        .replace("}]", "")
        .split("},{");

      // array format: ['"id":66,"name":"Nathalia","number":"5527988882406"', '"id":12,"name":"Mãe Trabalho","number":"5527999996918"', '"id":72,"name":"Mariah Irmã","number":"5527997420098"']

      const contactsArray = array.map((item) => {
        const obj = {};
        item.split(",").forEach((i) => {
          const [key, value] = i.split(":");
          obj[key.replace('"', "").replace('"', "")] = value
            ?.replace('"', "")
            .replace('"', "");
        });
        return obj;
      });

      return (
        <span className={classes.lastMessageSpan}>
          {message}
          <PersonIcon className={classes.lastMessageIcon} />
          {`${contactsArray[0].name} e outros ${
            contactsArray.length - 1
          } contatos`}
        </span>
      );
    }

    return (
      <span className={classes.lastMessageSpan}>
        <Typography
          noWrap
          component="span"
          variant="body2"
          color="textSecondary"
        >
          {`${message}${lastMessage.body}`}
        </Typography>
      </span>
    );
  };

  return (
    <React.Fragment key={ticket.id}>
      <AcceptTicketWithouSelectQueue
        modalOpen={acceptTicketWithouSelectQueueOpen}
        onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
        ticketId={ticket.id}
      />
      <Box style={{ position: "relative" }}>
        <ListItem
          dense
          button
          onClick={(e) => {
            if (ticket.status === "pending") return;
            handleSelectTicket(ticket.id);
          }}
          selected={ticketId && +ticketId === ticket.id}
          className={clsx(classes.ticket, {
            [classes.pendingTicket]: ticket.status === "pending",
          })}
          style={{
            minHeight: 72,
          }}
        >
          <Tooltip
            arrow
            placement="right"
            title={
              ticket.queue?.name ||
              queueName(ticket)?.name ||
              i18n.t("ticketsList.items.queueless")
            }
          >
            <span
              style={{
                backgroundColor:
                  ticket.queue?.color || queueName(ticket)?.color || "#7C7C7C",
              }}
              className={classes.ticketQueueColor}
            />
          </Tooltip>
          <ListItemAvatar>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={
                <>
                  {ticket.channel === "whatsapp" && (
                    <SmallAvatar
                      src={require("../../assets/icons/whatsapp.svg")}
                      style={{ backgroundColor: "#fff" }}
                    />
                  )}
                  {ticket.channel === "telegram" && (
                    <SmallAvatar
                      src={require("../../assets/icons/telegram.svg")}
                      style={{ backgroundColor: "#fff" }}
                    />
                  )}
                  {ticket.channel === "instagram" && (
                    <SmallAvatar
                      src={require("../../assets/icons/instagram.svg")}
                      style={{ backgroundColor: "#fff" }}
                    />
                  )}
                  {ticket.channel === "facebook" && (
                    <SmallAvatar
                      src={require("../../assets/icons/facebook.svg")}
                      style={{ backgroundColor: "#fff" }}
                    />
                  )}
                  {ticket.channel === "whatsappbusiness" && (
                    <SmallAvatar
                      src={require("../../assets/icons/wppbusiness.svg")}
                      style={{ backgroundColor: "#fff" }}
                    />
                  )}
                  {ticket.channel === "email" && (
                    <AlternateEmail
                      fontSize="small"
                      style={{ backgroundColor: "#fff", borderRadius: "50%" }}
                      color="primary"
                    />
                  )}
                </>
              }
            >
              {/* <Avatar
              src={
                ticket?.contact?.profilePicUrl ||
                (ticket?.contact?.isGroup?.toString() === "true" ? (
                  <PersonIcon />
                ) : (
                  <GroupIcon />
                ))
              }
            /> */}

              {profilePicUrl ? (
                <Avatar src={profilePicUrl} />
              ) : ticket?.contact?.isGroup ? (
                <Box className={classes.contactAvatar}>
                  <GroupIcon />
                </Box>
              ) : (
                <Box className={classes.contactAvatar}>
                  <PersonIcon />
                </Box>
              )}
            </Badge>
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <span className={classes.contactNameWrapper}>
                <Typography
                  noWrap
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {
                    ticket.channel !== "email"
                      ? ticket.contact.name
                      : ticket.contact.email
                  }

                  {
                    ticket.contact?.company
                      ? ` (${ticket.contact?.company})`
                      : null
                  }
                </Typography>

                {ticket.lastMessage && (
                  <Typography
                    className={
                      ticket.unreadMessages > 0
                        ? classes.lastUnreadMessageTime
                        : classes.lastMessageTime
                    }
                    component="span"
                    variant="body2"
                    color="textSecondary"
                    style={{
                      marginRight: userTagWidth - 20,
                    }}
                  >
                    {isSameDay(parseISO(lastUpdate), new Date()) ? (
                      <>{format(parseISO(lastUpdate), "HH:mm")}</>
                    ) : isYesterday(parseISO(lastUpdate)) ? (
                      <>Ontem</>
                    ) : (
                      <>{format(parseISO(lastUpdate), "dd/MM/yyyy")}</>
                    )}
                  </Typography>
                )}

                {ticket.whatsappId && (
                  <div
                    ref={userTagRef}
                    className={classes.userTag}
                    title={i18n.t("ticketsList.connectionTitle")}
                  >
                    {ticket.whatsapp?.name}
                  </div>
                )}
              </span>
            }
            secondary={
              <span className={classes.contactNameWrapper}>
                <Typography
                  className={classes.contactLastMessage}
                  noWrap
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {ticket.lastMessageObj ? (
                    // <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                    generateLastMessage(ticket.lastMessageObj, ticket?.isGroup)
                  ) : ticket.lastMessage ? (
                    <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                  ) : (
                    <br />
                  )}
                </Typography>

                <Badge
                  overlap="rectangular"
                  className={classes.newMessagesCount}
                  badgeContent={ticket.unreadMessages}
                  classes={{
                    badge: classes.badgeStyle,
                  }}
                />
              </span>
            }
          />
          {ticket.status === "pending" &&
            (ticket.queue === null || ticket.queue === undefined) && (
              <Tooltip title={i18n.t("ticketsList.items.accept")}>
                <IconButton
                  className={classes.bottomButton}
                  color="primary"
                  onClick={(e) => handleOpenAcceptTicketWithouSelectQueue()}
                  loading={loading.toString()}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
            )}
          {ticket.status === "pending" && ticket.queue !== null && (
            <Tooltip title={i18n.t("ticketsList.items.accept")}>
              <IconButton
                className={classes.bottomButton}
                color="primary"
                onClick={(e) => handleAcepptTicket(ticket.id)}
              >
                <DoneIcon />
              </IconButton>
            </Tooltip>
          )}
          {ticket.status === "pending" && (
            <Tooltip title={i18n.t("ticketsList.items.spy")}>
              <IconButton
                className={classes.bottomButton}
                color="primary"
                onClick={(e) => handleViewTicket(ticket.id)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {ticket.status === "pending" && (
          <Tooltip title={i18n.t("ticketsList.items.close")}>
            <IconButton
              className={classes.bottomButton}
              color="primary"
              onClick={(e) => handleClosedTicket(ticket.id)}
            >
              <ClearOutlinedIcon />
            </IconButton>
          </Tooltip>
        )} */}
          {ticket.status === "open" && (
            <Tooltip title={i18n.t("ticketsList.items.reopen")}>
              <IconButton
                className={classes.bottomButton}
                color="primary"
                onClick={(e) => handleReturnTicket(ticket.id)}
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {ticket.status === "open" && (
          <Tooltip title={i18n.t("ticketsList.items.close")}>
            <IconButton
              className={classes.bottomButton}
              color="primary"
              onClick={(e) => handleClosedTicket(ticket.id)}
            >
              <ClearOutlinedIcon />
            </IconButton>
          </Tooltip>
        )} */}
          {ticket.status === "closed" && (
            <IconButton
              className={classes.bottomButton}
              color="primary"
              onClick={(e) => handleReopenTicket(ticket.id)}
            >
              <ReplayIcon />
            </IconButton>
          )}
          {ticket.status === "closed" && (
            <IconButton className={classes.bottomButton} color="primary" />
          )}
        </ListItem>
        <Box className={classes.tagsBox}>
          {ticket.contact?.tags?.length > 0 &&
            ticket.contact?.tags?.map((tag) => (
              <Tooltip key={tag.id} title={tag.name} placement="top">
                <Box
                  className={classes.tag}
                  style={{ backgroundColor: tag.color }}
                >
                  {tag?.name}
                </Box>
              </Tooltip>
            ))}
        </Box>

        <Divider
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "87.5%",
          }}
        />
      </Box>
    </React.Fragment>
  );
}

export default TicketListItem;
