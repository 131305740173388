const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "E-mail",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Seja bem-vindo!",
        form: {
          email: "Insira o e-mail",
          password: "Coloque a sua senha",
        },
        buttons: {
          submit: "Fazer login",
          register: "Não tem um conta? Cadastre-se!",
          recover: "Esqueci minha senha",
        },
      },
      recoverPassword: {
        title: "Esqueceu sua senha?",
        subTitle:
          "Sem problemas! Basta inserir seu e-mail e enviaremos as intruções de login.",
        form: {
          email: "Insira o e-mail",
        },
        buttons: {
          submit: "Recuperar senha",
          return: "Voltar",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      dashboard: {
        epoch: {
          buttons: {
            today: "No dia",
            week: "Na semana",
            month: "No mês",
            year: "No ano",
          },
        },
        charts: {
          perDay: {
            title: "Tickets hoje: ",
          },
          attendantsChart: {
            title: "Atendimentos ativos por atendentes",
            sideTitle: "Atendimentos",
          },
          progressBar: {
            callsByDepartment: "Atendimentos por departamento",
            callsByAttendant: "Atendimentos por atendente",
            ratingsByAttendant: "Avaliações por atendente",
          },
        },
        departments: {
          title: "Departamentos",
        },
        messages: {
          inAttendance: {
            title: "Atendendo",
          },
          waiting: {
            title: "Aguardando",
          },
          closed: {
            title: "Resolvido",
          },
          connection: {
            title: "Conexões",
          },
          waitingTime: {
            title: "Tempo médio de espera",
          },
          serviceTime: {
            title: "Tempo médio de atendimento",
          },
          averageRating: {
            title: "Média de avaliação",
          },
          contacts: {
            title: "Contatos",
          },
          messages: {
            title: "Mensagens",
          },
          reviews: {
            title: "Avaliações",
          },
        },
        infoCard: {
          ticketsToday: "Quantidade de Tickets que estão sendo atendidos.",
          awaitingService: "Quantidade de Tickets aguardando atendimento.",
          ticketsSolved: "Quantidade de Tickets resolvidos.",
          connections: "Quantidade de canais conectados.",
          waitingTime:
            "Tempo médio em que os Tickets esperam para serem atendidos.",
          serviceTime:
            "Tempo médio em que os operadores realizam o atendimento.",
          averageRating: "Nota média de avaliação dos atendimentos.",
          contactNumber: "Quantidade total de Contatos.",
          messagesNumber: "Quantidade total de mensagens trocadas.",
          reviews: "Quantidade total de avaliações recebidas.",
        },
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar Conexão",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
          restart: "Reinicia Backend",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que os dados de conexão estão corretos e tente novamente",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão não é válida ou foi perdida",
            content:
              "Certifique-se de que os dados de conexão estão corretos e tente novamente.",
            contentEmail:
              "Certifique-se que seu servidor de recebimento de e-mail esteja configurado corretamente e tente novamente. Caso o problema persista, remova e recrie a conexão com dados atualizados.",
          },
        },
        table: {
          id: "ID da Instância",
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          battery: "Bateria",
          actions: "Ações",
          session: "Sessão",
          channel: "Canal",
        },
      },
      permissionModal: {
        title: "Ação não autorizada",
        content: "Você não tem permissão para realizar a ação.",
      },
      whatsappModal: {
        title: {
          add: "Adicionar Conexão",
          edit: "Editar Conexão",
        },
        form: {
          name: "Nome da conexão",
          default: "Padrão",
          channel: "Canal",
          farewellMessage: "Mensagem de despedida",
          whatsapp: "WhatsApp",
          facebook: "Facebook",
          telegram: "Telegram",
          instagram: "Instagram",
          whatsappcloud: "WhatsApp API",
          connectionInfo: "Informações de conexão",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Conexão salva com sucesso.",
      },
      qrCode: {
        title: "Para conectar ao Notificame:",
        firstPoint: "Abra o WhatsApp em seu celular.",
        secondPoint: {
          partOne: "Toque em ",
          moreOptions: "Mais opções ",
          partTwo: " ou ",
          configurations: "Configurações ",
          partThree: "e selecione Aparelhos conectados.",
        },
        thirdPoint: "Toque em Conectar um aparelho.",
        fourthPoint:
          "Aponte seu celular para esta tela para capturar o código.",
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importar todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          export: "Exportar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "E-mail",
          company: "Empresa",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          company: "Empresa",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar Setor",
          edit: "Editar Setor",
        },
        notification: {
          title: "Setor salvo com sucesso!",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          startWork: "Abertura",
          endWork: "Fechamento",
          absenceMessage: "Mensagem de ausência",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "E-mail",
          password: "Senha",
          profile: "Perfil",
          admin: "Administrador",
          whatsapp: "Conexão Padrão",
          user: "Usuário",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
        noChatDescription:
          "Envie e receba mensagens sem precisar manter seu celular conectado à internet",
        noChatDescriptionText:
          "Use o NotificaMe em quantos computadores quiser e um celular ao mesmo tempo.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Setores",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      templateOnlyBar: {
        warningBefore:
          "Você só pode responder a esta conversa usando um modelo de mensagem devido a ",
        warningAfter: "restrições de janela de mensagem de 24 horas",
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para o Setor",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Transferir para o Setor",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        items: {
          queueless: "Sem Setor",
          accept: "Aceitar",
          spy: "Espiar",
          close: "Encerrar",
          reopen: "Reabrir",
        },
        buttons: {
          accept: "Responder",
          acceptBeforeBot: "Aceitar",
          start: "iniciar",
          cancel: "Cancelar",
        },
        acceptModal: {
          title: "Aceitar Chat",
          queue: "Selecionar setor",
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Conversas",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          queues: "Setores",
          tags: "Etiquetas",
          schedules: "Agendamentos",
          administration: "Administração",
          users: "Usuários",
          settings: "Configurações",
          sendMsg: "Envio de Mensagens",
          sendMedia: "Envio de Mídia",
          api: "Uso da API",
          apidocs: "Documentação",
          apititle: "API",
          apikey: "API Key",
          token: "Token",
        },
        appBar: {
          site: {
            title: "NotificaMe",
          },
          user: {
            profile: "Configurações do Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },

      queues: {
        title: "Setores",
        notifications: {
          queueDeleted: "O setor foi deletado.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
          startWork: "Abertura",
          endWork: "Fechamento",
        },
        buttons: {
          add: "Adicionar setor",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets desse setor continuarão existindo, mas não terão mais nenhuma setor atribuído.",
        },
      },
      queueSelect: {
        inputLabel: "Setores",
      },
      autoQueueSelect: {
        inputLabel: "Chatbot: Seleção automática de setor",
        empty: "Nenhum setor selecionado",
      },
      chatbotMaxAttempts: "Chatbot: Máximo de tentativas",
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "E-mail",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a espera.",
        },
      },
      templates: {
        title: "Templates",
        table: {
          type: "Tipo",
          status: "Status",
          name: "Nome",
          email: "Email",
          category: "Categoria",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
          header: "Cabeçalho",
          body: "Corpo",
          footer: "Rodapé",
          statuses: {
            APPROVED: "Aprovado",
            PENDING: "Pendente",
            REJECTED: "Rejeitado",
          },
          types: {
            UTILITY: "Utilidade",
            MARKETING: "Marketing",
            AUTHENTICATION: "Autenticação",
          },
        },
        buttons: {
          add: "Adicionar template",
        },
        toasts: {
          deleted: "Template deletado com sucesso.",
        },
        templateModal: {
          title: {
            create: "Adicionar template",
            update: "Editar template",
          },
          connection: "Conexão",
          name: "Nome",
          header: "Cabeçalho",
          body: "Corpo",
          footer: "Rodapé",
          buttons: {
            create: "Criar",
            update: "Atualizar",
            cancel: "Cancelar",
          },
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Todas as informações do template serão perdidas.",
        },
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        },
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      tags: {
        title: "Etiquetas",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir esta etiqueta?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Registros Etiquetados",
          actions: "Ações",
          contacts: "Contatos",
        },
        buttons: {
          add: "Adicionar Etiqueta",
        },
        toasts: {
          deleted: "Etiqueta excluído com sucesso.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          CheckMsgIsGroup: {
            name: "Ignorar Mensagens de Grupos",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          call: {
            name: "Aceitar chamadas",
            note: "Se desabilitado, o cliente receberá uma mensagem informando que não aceita chamadas de voz/vídeo",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          sideMenu: {
            name: "Menu Lateral Inicial",
            note: "Se habilitado, o menu lateral irá iniciar fechado",
            options: {
              enabled: "Aberto",
              disabled: "Fechado",
            },
          },
          closeTicketApi: {
            name: "Encerrar Ticket enviado API",
            note: "Fecha automaticamente o ticket quando enviado por API",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          darkMode: {
            name: "Ativa Modo Escuro",
            note: "Alternar entre o modo claro e o modo escuro",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          timeCreateNewTicket: {
            name: "Cria novo ticket após",
            note: "Selecione o tempo que será necessário para abrir um novo ticket, caso o cliente entre em contatos novamente",
            options: {
              10: "10 Segundos",
              30: "30 Segundos",
              60: "1 minuto",
              300: "5 minutos",
              1800: "30 minutos",
              3600: "1 hora",
              7200: "2 horas",
              21600: "6 horas",
              43200: "12 horas",
              86400: "24 horas",
              604800: "7 dias",
              1296000: "15 dias",
              2592000: "30 dias",
            },
          },
          sortBy: {
            nameOpen: "Ordena tickets abertos por",
            namePending: "Ordena tickets pendentes por",
            nameClosed: "Ordena tickets fechados por",
            note: "Selecione a ordem em que deseja ver os tickets",
            options: {
              asc: "Mais antigos",
              desc: "Mais recentes"
            }
          }
        },
      },
      messagesList: {
        header: {
          assignedTo: "Responsável:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
            solverWithoutGoodbye: "Resolver sem despedida",
          },
        },
      },

      // press ''/'' to use the registered quick responses
      messagesInput: {
        placeholderOpen:
          "Digite uma mensagem ou insira ''/'' para ver as respostas rápidas.",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      searchDrawer: {
        header: "Buscar mensagem",
        searchLabel: "Pesquisar",
        yesterday: "ontem",
        locale: "ptBR",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        confirmationModal: {
          title: "Deletar o ticket #",
          titleFrom: "do contato ",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      languages: {
        undefined: "Idioma",
        "pt-BR": "Português",
        es: "Español",
        en: "English",
      },
      tagModal: {
        title: {
          add: "Criar Etiqueta",
          edit: "Editar Etiqueta",
        },
        form: {
          name: "Nome",
          color: "Cor",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Etiqueta salva com sucesso.",
      },
      backendErrors: {
        TICKET_NOT_FOUND: "Ticket não encontrado.",
        BULK_HAS_NO_CONTACTS_TO_SEND:
          "Não há contatos novos (verifique o reenvio em caso de falhas).",
        ERR_TAG_NAME_ALREADY_EXISTS: "Já existe uma tag com esse nome.",
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_BULK_INVALID_CONTACT: "Todos os contatos não são números de Whatsapp válidos ou há um problema com a conexão.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um ticket aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum ticket encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar ticket no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhatsApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de um Setor.",
        ERR_USER_CREATION_COUNT:
          "Limite de usuários atingido, para alterar entre em contato com o suporte.",
        ERR_CONNECTION_CREATION_COUNT:
          "Limite de conexões atingido, para alterar entre em contato com o suporte.",
        BULK_HAS_NO_CONTACTS_TO_SEND: "Não há contatos para enviar mensagens em massa."
      },
    },
  },
};

export { messages };
