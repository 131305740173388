import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  Tooltip,
  Hidden,
  Box,
  Paper,
  Button,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";

import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import KeyboardOutlinedIcon from "@material-ui/icons/KeyboardOutlined";
import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";

import api from "../services/api";
import toastError from "../errors/toastError";

import logodash from "../assets/logo-dash.png";
import { system } from "../../package.json";
import UserLanguageSelector from "../components/UserLanguageSelector";
import { ThemeContext } from "../context/Theme/ThemeContext";
import { MoreVert } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "48px",
  },
  appBar: {
    backgroundColor: "#00a884",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //boxShadow: "inset 0px -14px 5px -10px rgba(0,0,0,0.2)",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "60px",
    // width: theme.spacing(7),
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(6),
    // },
  },
  appBarSpacer: {
    minHeight: "48px",
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  systemCss: {
    opacity: "0.5",
    fontSize: "12px",
    marginLeft: "8px",
  },

  menuTitle: {
    fontSize: "0.9em",
    marginLeft: "1.3em",
    marginBottom: "0.5em",
  },

  menuItem: {
    fontSize: "0.9em",
  },

  onlineDot: {
    marginLeft: 0,
    marginRight: "3px",
    // marginBottom: "-3px",
    fontSize: "1.2em",
    color: "#00FF00",
  },

  busyDot: {
    marginLeft: 0,
    marginRight: "3px",
    // marginBottom: "-3px",
    fontSize: "1.2em",
    color: "#DAA520",
  },

  offlineDot: {
    marginLeft: 0,
    marginRight: "3px",
    // marginBottom: "-3px",
    fontSize: "1.2em",
    color: "#FF0000",
  },

  menuIcon: {
    fontSize: "1.4em",
    fontWeight: "100",
    marginRight: "0.3em",
    color: "#737c83",
  },
}));

function LoggedInLayout({ children }) {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const { isSocketConnected } = useContext(AuthContext);

  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      const fetchDrawerState = async () => {
        try {
          const { data } = await api.get("/settings");

          const settingIndex = data.filter((s) => s.key === "sideMenu");

          setDrawerOpen(settingIndex[0].value !== "disabled");
        } catch (err) {
          setDrawerOpen(true);
          toastError(err);
        }
      };
      fetchDrawerState();
    }
  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const updateUserStatus = (status) => {
    if (user) {
      try {
        api.put(`/users/${user.id}`, { status });
        setMenuOpen(false);
      } catch (err) {
        toastError(err);
      }
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorMenuEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      {!isSocketConnected && (
        <Box
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            zIndex: 9999999,
            // glass blur
            background: "rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(5px)",
          }}
        >
          <Paper
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "1em 3em",
              width: "80%",
              maxWidth: "400px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                marginBottom: "1em",
                textTransform: "uppercase",
              }}
            >
              Sessão finalizada
            </Typography>
            <Typography variant="body1">
              Este usuário foi acessado em outra aba do navegador ou
              dispositivo.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: "1em",
              }}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Reconectar
            </Button>
          </Paper>
        </Box>
      )}

      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <img src={logodash} alt="logo" />
          <IconButton
            color="secondary"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems drawerClose={drawerClose} />
        </List>
        <Divider />
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}

        /* color={process.env.NODE_ENV === "development" ? "inherit" : "primary"} */
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {system.name}
            <span className={classes.systemCss}>
              (v
              {system.version})
            </span>
          </Typography>

          <Hidden only={["sm", "xs"]}>
            <UserLanguageSelector className />
            <IconButton
              aria-label="toggle-dark-mode"
              onClick={handleToggleDarkMode}
              color="inherit"
            >
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Hidden>

          {user.id && <NotificationsPopOver />}

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {user.status === "online" ? (
                <Tooltip title="Disponível">
                  <FiberManualRecordIcon
                    className={classes.onlineDot}
                    style={{
                      fontSize: "0.5em",
                      marginRight: "-0.5em",
                      marginBottom: "-1.8em",
                    }}
                  />
                </Tooltip>
              ) : user.status === "offline" ? (
                <Tooltip title="Desconectado">
                  <FiberManualRecordIcon
                    className={classes.offlineDot}
                    style={{
                      fontSize: "0.5em",
                      marginRight: "-0.5em",
                      marginBottom: "-1.8em",
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Ocupado">
                  <FiberManualRecordIcon
                    className={classes.busyDot}
                    style={{
                      fontSize: "0.5em",
                      marginRight: "-0.5em",
                      marginBottom: "-1.8em",
                    }}
                  />
                </Tooltip>
              )}
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <Typography className={classes.menuTitle}>Defina como</Typography>
              <MenuItem
                className={classes.menuItem}
                onClick={() => updateUserStatus("online")}
              >
                <FiberManualRecordIcon className={classes.onlineDot} />
                Disponível
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() => updateUserStatus("busy")}
              >
                <FiberManualRecordIcon className={classes.busyDot} />
                Ocupado
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() => updateUserStatus("offline")}
              >
                <FiberManualRecordIcon className={classes.offlineDot} />
                Desconectado
              </MenuItem>
              <Divider style={{ marginBottom: "0.5em", marginTop: "0.5em" }} />
              <MenuItem
                className={classes.menuItem}
                // onClick={handleOpenUserModal}
              >
                <KeyboardOutlinedIcon className={classes.menuIcon} />
                Atalhos do teclado
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={handleOpenUserModal}
              >
                <PersonOutlineOutlinedIcon className={classes.menuIcon} />
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={handleClickLogout}
              >
                <PowerSettingsNewIcon className={classes.menuIcon} />
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>

          <Hidden only={["md", "lg", "xl"]}>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenMenuClick}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={anchorMenuEl}
              open={Boolean(anchorMenuEl)}
              onClose={handleMenuItemClick}
            >
              <MenuItem>
                <UserLanguageSelector className />
              </MenuItem>

              <MenuItem>
                <IconButton
                  aria-label="toggle-dark-mode"
                  onClick={handleToggleDarkMode}
                  color="inherit"
                >
                  {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </MenuItem>
            </Menu>
          </Hidden>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children || null}
      </main>
    </div>
  );
}

export default LoggedInLayout;
