import React, { useContext, useEffect, useReducer, useState } from "react";

import { Box } from "@material-ui/core";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import { AuthContext } from "../../context/Auth/AuthContext";
import useTickets from "../../hooks/useTickets";
import openSocket from "../../services/socket-io";
import { i18n } from "../../translate/i18n";
import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";
import { useStyles } from "./styles";

const reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;

    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        }
      } else {
        state.push(ticket);
      }
    });

    state = state.sort((ticketA, ticketB) => {
      let dateA;
      let dateB;

      if (ticketA.lastMessageObj?.createdAt) {
        dateA = new Date(ticketA.lastMessageObj.createdAt).getTime();
      } else {
        dateA = new Date(ticketA.updatedAt).getTime();
      }

      if (ticketB.lastMessageObj?.createdAt) {
        dateB = new Date(ticketB.lastMessageObj.createdAt).getTime();
      } else {
        dateB = new Date(ticketB.updatedAt).getTime();
      }

      if (dateA > dateB) return -1

      if (dateA < dateB) return 1

      return 0;
    })

    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
      state.unshift(state.splice(ticketIndex, 1)[0]);
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

export default function TicketsList(props) {
  const {
    status,
    searchParam,
    showAll,
    selectedQueueIds,
    updateCount,
    sortBy,
    style,
    searched,
    showUnread,
  } = props;

  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const [ticketSetObj, setTicketSetObj] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [status, searched, dispatch, showAll, selectedQueueIds, showUnread]);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    showUnread,
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect(() => {
    if (!status && !searchParam) return;
    dispatch({
      type: "LOAD_TICKETS",
      payload: tickets.filter(
        (ticket) =>
          (!ticket.userId || ticket.userId === user.id || showAll) &&
          (selectedQueueIds.includes(ticket.queueId) || !ticket.queueId)
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets, status, searched, selectedQueueIds]);

  useEffect(() => {
    const socket = openSocket({
      scope: `ticketslist-${status}`,
      userId: user.id,
      component: "TicketsList",
    });

    let shouldUpdateTicket = () => {};
    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

    if (user?.profile === "admin" || user?.canSeeAllTickets) {
      shouldUpdateTicket = (ticket) =>
        // (!ticket.userId || ticket.userId === user?.id || showAll) &&
        !ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1;
    } else {
      shouldUpdateTicket = (ticket) =>
        // return (
        // 	(ticket.userId === user?.id || showAll) &&
        // 	(!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1)
        // );
        // ((ticket.userId === user?.id || showAll || !ticket.userId) &&
        (!ticket.queueId && user?.canSeeAllTickets) ||
        selectedQueueIds.indexOf(ticket.queueId) > -1;
    }

    socket.on("connect", () => {
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on("ticket", (data) => {
      if (
        data.action === "updateUnread" &&
        (data.ticket.userId === user?.id || showAll || !data.ticket.userId)
      ) {
        dispatch({
          type: "RESET_UNREAD",
          payload: data.ticketId,
        });
      }

      if (
        data.action === "update" &&
        shouldUpdateTicket(data.ticket)
        // && (data.ticket.userId === user?.id || showAll || !data.ticket.userId)
      ) {
        dispatch({
          type: "UPDATE_TICKET",
          payload: data.ticket,
        });

        if (data.ticket.status !== status) {
          dispatch({
            type: "DELETE_TICKET",
            payload: data.ticket.id,
          });
        }
      }

      if (
        data.action === "update" &&
        data.ticket.userId !== user?.id &&
        !showAll &&
        data.ticket.status === "open"
        // (data.ticket.userId === user?.id || showAll || !data.ticket.userId)
      ) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (
        data.action === "update" &&
        notBelongsToUserQueues(data.ticket) &&
        (data.ticket.userId === user?.id || showAll || !data.ticket.userId)
      ) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket?.id });
      }
    });

    socket.on("appMessage", (data) => {
      if (
        data.action === "create" &&
        shouldUpdateTicket(data.ticket) &&
        (data.ticket.userId === user?.id ||
          showAll ||
          (data.ticket.status === "pending" && !data.ticket.userId))
      ) {
        dispatch({
          type: "UPDATE_TICKET_UNREAD_MESSAGES",
          payload: data.ticket,
        });
      }
    });

    socket.on("contact", (data) => {
      if (
        data.action === "update" &&
        (data.ticket?.userId === user?.id || showAll || !data.ticket?.userId)
      ) {
        dispatch({
          type: "UPDATE_TICKET_CONTACT",
          payload: data.contact,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [status, showAll, showUnread, user, selectedQueueIds]);

  useEffect(() => {
    if (typeof updateCount === "function") {
      // if (showAll) {
      if (hasMore) {
        updateCount(`${ticketsList.length}+`);
      } else {
        updateCount(ticketsList.length);
      }
      // } else {
      // const filteredTickets = ticketsList.filter((ticket) => {ticket.userId === user?.id});
      // updateCount(ticketsList.length);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);

  // WORKING
  useEffect(() => {
    // this
    const getTicketConnectionNameArray = (ticketList) => {
      const allTicketConnectionNames = ticketList.map(
        (item) => item.whatsapp?.name
      );
      const ticketConnectionNameSet = new Set(allTicketConnectionNames);
      return ticketConnectionNameSet;
    };
    const ticketSet = getTicketConnectionNameArray(ticketsList);
    const ticketSetArray = [];
    [...ticketSet].forEach((ticketChannelname) => {
      ticketSetArray.push({ name: ticketChannelname, tickets: [] });
    });

    ticketSetArray.forEach((item) => {
      ticketsList.forEach((ticket) => {
        if (ticket.whatsapp?.name === item.name) {
          item.tickets.push(ticket);
        }
      });
    });
    setTicketSetObj(ticketSetArray);
  }, [ticketsList]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const sortedTickets = [...ticketsList].sort((a, b) => {
    const dateA = new Date(a.updatedAt);
    const dateB = new Date(b.updatedAt);
  
    return sortBy === 'asc' ? dateA - dateB : dateB - dateA;
  });

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0 }}>
          {sortedTickets.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <Box overflow="scroll" style={{ maxHeight: "100%" }}>
              {/*  {ticketSetObj.map((item) => (
                <TicketAccordion
                  connectionName={item.name}
                  key={item.name}
                  tickets={item.tickets}
                />
              ))} */}
              {sortedTickets.map((ticket) => {
                return (
                  <TicketListItem
                    key={ticket.id}
                    ticket={ticket}
                    updateCount={updateCount}
                  />
                );
              })}
            </Box>
          )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
}
